.containerLoading {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(69, 69, 69);
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  z-index: 998;
}

.containerHide {
  display: none;
}

.loadingText {
  font-size: 2rem;
}

.loadingSpinner {
  margin-top: 1rem;
  font-size: 2rem;
}

.oneRow {
  flex-direction: row;
}

.oneRow .loadingSpinner {
  margin-top: 0;
  margin-left: 1rem;
}
