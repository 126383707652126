.menu {
  width: 260px;
  margin-top: 4px;
}

.menuItem {
  display: flex;
  align-items: center;
  font-size: 16px;
  transition: all 0.1s linear;
}

.menuIcon {
  margin-right: 4px;
}

.menuItem:hover {
  color: white;
  background-color: #015716;
}