.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  height: 28px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;

  color: white;
  background-color: #015716;

  z-index: 1000;
}