.burgerButton {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  padding: 0;

  font-size: 48px;

  color: white;
}

@media (min-width: 1025px) {
  .burgerButton {
    display: none;
  }
}