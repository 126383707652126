.asideMenu {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;

  padding-top: 48px;
  padding-bottom: 28px;

  width: 240px;

  background-color: rgb(51, 51, 51);

  z-index: 999;
}

@media (max-width: 1024px) {
  .asideMenu {
    background-color: rgba(51, 51, 51, 0.9);
    transition: left 0.2s ease-in-out;
  }

  .hide {
    left: -240px;
  }
}