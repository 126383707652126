.menuItem {
  height: 38px;
  margin-bottom: 4px;
  padding-left: 4px;

  display: flex;
  align-items: center;

  font-size: 24px;

  background-color: transparent;
  color: rgb(175, 175, 175);

  transition: all 0.1s linear;
}

.menuItem:hover {
  color: white;
  background-color: #202020;
}

.active {
  border-left: 4px solid #018d24;
}

.itemContent {
  display: flex;
  align-items: center;
}

.itemText {
  margin-left: 4px;
}

@media (max-width: 1024px) {
  .menuItem {
    color: rgba(255, 255, 255, 0.9);
  }
}