.link {
  text-decoration: none;
  color: white;
  height: 100%;

  display: flex;
  align-items: center;
}

.linkImage {
  height: 100%;
}

.linkText {
  margin-left: 4px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
}

@media (max-width: 1024px) {
  .link {
    margin-left: 8px;
  }

  .linkText {
    font-size: 14px;
    width: 200px;
  }
}