@keyframes shake {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  10% {
    transform: translate(-0.25px, -0.25px) rotate(-0.1deg);
  }

  20% {
    transform: translate(-0.5px, 0px) rotate(0.1deg);
  }

  30% {
    transform: translate(0.5px, 0.5px) rotate(0deg);
  }

  40% {
    transform: translate(0.25px, -0.25px) rotate(0.1deg);
  }

  50% {
    transform: translate(-0.25px, 0.25px) rotate(-0.1deg);
  }

  60% {
    transform: translate(-0.5px, 0px) rotate(0deg);
  }

  70% {
    transform: translate(0.5px, 0px) rotate(-0.1deg);
  }

  80% {
    transform: translate(-0.25px, -0.25px) rotate(0.1deg);
  }

  90% {
    transform: translate(0.25px, 0.25px) rotate(0deg);
  }

  100% {
    transform: translate(0px, -0.25px) rotate(-0.1deg);
  }
}

.widget {
  position: relative;

  /* width: 100%; */
  width: 340px;
  height: 296px;

  margin: 8px;

  background-color: #fff;

  overflow: hidden;
}

.widgetHeader {
  display: flex;
  justify-content: space-between;
}

.widgetName,
.link {
  color: black;
  font-weight: bold;
  text-wrap: nowrap;
}

.link:hover {
  text-decoration: underline;
  color: green
}

.widgetActions {
  display: flex;
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;
  height: 22px;
  width: 22px;

  border-radius: 50%;

  color: grey;
  background-color: transparent;

  font-size: 16px;

  transition: all 0.1s ease-in-out;
}

.actionButton:hover {
  color: white;
  background-color: rgba(27, 68, 0, 0.7);
}

.shake {
  animation: shake 1s;
  animation-iteration-count: infinite;

  cursor: grab;
}