.container {
  display: flex;
}

.compassContaier {
  position: relative;
  padding: 6px;
  width: 212px;
  height: 212px;
  border-radius: 50%;
}

.arrowImage {
  position: absolute;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: transform 0.5s ease-in-out;
}
