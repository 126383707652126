.overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 6px;

  height: 100%;
}

.actions {
  display: flex;
  justify-content: space-around;
}

.content {
  text-align: center;
}

.header {
  font-size: 28px;
}

.widgetName {
  margin-top: 6px;
  font-weight: bold;
  font-size: 20px;
}
