.workSpace {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  padding: 54px 6px 34px 246px;
}

@media (max-width: 1024px) {
  .workSpace {
    padding: 50px 2px 30px 2px;
  }
}