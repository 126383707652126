.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.value {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.valueText {
  font-weight: bold;
}

.valueUnits {
  font-size: 10px;
}
