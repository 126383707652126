.header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkButton {
  margin-left: 12px;
}

.link {
  color: grey;
  transition: all 0.1s ease-in-out;
}

.link:hover {
  color: white;
  background-color: rgba(27, 68, 0, 0.7);
}