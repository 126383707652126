.container {
  min-height: 100%;
  padding-bottom: 32px;
}

.card {
  position: relative;
  min-height: calc(100vh - 86px);
  z-index: 0;
}

.tabHeaders {
  display: flex;
  flex-wrap: wrap;
}

.tabHeader {
  display: flex;
  position: relative;
  padding: 12px 4px 4px 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  border-top: 1px solid rgba(27, 68, 0, 0.1);
  border-left: 1px solid rgba(27, 68, 0, 0.1);
  border-right: 1px solid rgba(27, 68, 0, 0.1);
  border-radius: 8px 8px 0 0;


  z-index: 1;


  transition: all 0.1s linear;

  cursor: grab;
}

.tabHeader::after {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  border-top-right-radius: 8px;
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 80%,
      rgb(255, 255, 255) 100%);
}

.activeTabHeader {
  padding-right: 52px;
  font-weight: bold;

  border-top: 1px solid rgba(27, 68, 0, 0.7);
  border-left: 1px solid rgba(27, 68, 0, 0.7);
  border-right: 1px solid rgba(27, 68, 0, 0.7);

  background: linear-gradient(0deg,
      rgba(255, 0, 0, 0) 0%,
      rgba(255, 0, 0, 0) 0%,
      rgba(1, 87, 22, 0.5) 100%);
}

.activeTabHeader.tabHeader::after {
  background: rgba(188, 255, 205, 0.007)
}

.activeDashboardHeader::after {
  background: transparent;
}

.dashboardsEmptyMessage {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 16px;

  font-size: 18px;
}


.mobileTabsContainer {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobileTabName {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px;
  margin-right: 4px;
  width: 100%;
  overflow: hidden;
  z-index: 0;
  cursor: pointer;

}

.mobileTabName::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 30px;
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 80%,
      rgb(255, 255, 255) 100%);
  z-index: 1;
}

.mobileShowList {
  margin-right: 4px;
}

.mobileTabsActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mobileActionButtons {
  position: static;

}

.mobileAddButton {
  margin-left: 1%;
}

.mobileTab {
  display: flex;
  overflow: hidden;
  padding: 4px;
  cursor: pointer;
}

.mobileHandle {
  color: rgba(27, 68, 0, 0.5);
  margin-right: 4px;
}

.mobileTabs {
  display: none;
}

@media (max-width: 1024px) {
  .pcTabs {
    display: none;
  }

  .mobileTabs {
    display: block;
  }
}