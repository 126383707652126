.container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;

  font-size: 100px;

  color: rgba(128, 128, 128, 0.70);
  background-color: rgba(255, 255, 255, 0.7);
}