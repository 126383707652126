.chartContainer {
  width: 100%;
  height: 228px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.togglers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.legendContainer {
  display: flex;
  justify-content: space-around;
  height: 38px;
}

.legend {
  display: flex;
  align-items: center;
}

.legendColorBlock {
  width: 20px;
  height: 14px;
  border-radius: 4px;
}

.legendText {
  font-size: 12px;
  margin-left: 2px;
}

.legendContainerColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chart {
  cursor: crosshair;
}

.footer {
  height: 20px;
  display: flex;
  justify-content: space-between;
}

.progressBar {
  width: 60%;
  height: 18px;
}

.dateText {
  font-size: 16px;
  font-style: italic;
  text-align: right;
}
