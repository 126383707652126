.link {
  color: black;

  transition: color 0.2s linear;
}

.link:hover {
  color: green;
  text-decoration: underline;
}

.status,
.network {
  text-align: center;
}


.yellowStatus {
  color: yellow;
}

.redStatus {
  color: red
}

.greenStatus {
  color: green
}