.userButton {
  display: flex;
  align-items: center;

  color: white;
}

.userPhoto {
  width: 36px;
  height: 36px;
  border-radius: 50%;

  font-size: 28px;

  color: black;
  background-color: white;
}

.userName {
  font-size: 18px;
  margin-left: 6px;
  line-height: 1;
}

@media (max-width: 1025px) {
  .userName {
    display: none;
  }
}