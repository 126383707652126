.smallButton {

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0;
  height: 22px;
  width: 22px;

  border-radius: 50%;

  color: grey;
  background-color: transparent;

  font-size: 16px;

  transition: all 0.1s ease-in-out;
}

.smallButton:hover {
  color: white;
  background-color: rgba(27, 68, 0, 0.7);
}