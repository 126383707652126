.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  padding: 6px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.actionButton {
  margin-left: 6px;
  font-size: 14px;
}
