.container {}

.row {
  height: auto;
  text-align: center;
  vertical-align: middle;
}

.status {
  width: 100px;
}

.networkStatus {
  width: 100px;
}

.settings {
  width: 10px;
}