.main {
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.greetingMessage {
  font-size: 54px;
  font-weight: bold;
  text-align: center;
}