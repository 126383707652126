.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  padding: 4px;

  background-color: rgba(255, 255, 255, 0.9);

  transition: all 0.1s ease-in-out;
}

.show {
  top: -300px;
  bottom: 300px;
}
