.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.header {
  font-size: 10rem;
  color: green;
}

.text {
  margin-top: 1rem;
  font-size: 4rem;
  text-align: center;
}