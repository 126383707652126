.header {
  position: fixed;

  width: 100%;
  height: 48px;
  padding: 2px 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #015716;

  z-index: 1000;
}

.logoSection {
  display: flex;
  height: 100%;
}

@media (max-width: 1024px) {
  .header {
    width: 100%;
    height: 40px;
    padding: 2px 8px;
  }
}