.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 296px;
}
.card {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 160px;
  height: 160px;

  border-radius: 50%;
}

.addButton {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 160px;
  height: 160px;

  font-size: 160px;

  color: gray;

  border-radius: 50%;

  transition: all 0.2s ease-in-out;
}

.addButton:hover {
  color: white;
  background-color: rgba(27, 68, 0, 0.7);
}
