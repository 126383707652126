.container {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 54px 6px 34px 6px;
}

.logIn {
  position: relative;
  width: 366px;
  height: 280px;
}

.submitButton {
  position: absolute;
  bottom: 6px;
  right: 6px;
}