.dashboard {
  padding: 4px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.draggableToggler {
  position: relative;
  display: flex;
  align-items: center;
}

.draggableIcon {
  position: relative;
  font-size: 20px;
  top: 2px;
}

.headerDashboardName {
  font-size: 18px;
  font-weight: bold;
}