.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 258px;
}

.speedometr {
  padding: 6px;
  min-width: 212px;
  height: 212px;
  border-radius: 50%;
}

.mainData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
}

.values {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.value {
  font-size: 48px;
  font-weight: bold;
}

.units {
  font-size: 28px;
}

.date {
  font-size: 16px;
  font-style: italic;
}
